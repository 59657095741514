<template>
    <div>
        <b-notification
            type="is-danger"
            v-model="hasErrors"
            has-icon
            aria-close-label="Cerrar notificación"
            role="alert"
            @close="errors = []">
            <p>Los siguientes campos presentan los siguientes errores, por favor corríjalos:</p>
            <ul>
                <li v-for="(field, i) in errors" :key="i">
                    {{ field }}
                </li>
            </ul>
        </b-notification>
        <b-notification
            type="is-danger"
            v-model="fileTooLargeError"
            has-icon
            aria-close-label="Cerrar notificación"
            role="alert"
            @close="fileTooLargeError = false">
            <p>El archivo que trata de cargar es muy grande</p>
        </b-notification>
        <b-notification
            type="is-danger"
            v-model="otherError"
            has-icon
            aria-close-label="Cerrar notificación"
            role="alert"
            @close="otherError = false">
            <p>Un error no contemplado sucedio contáctenos para rastrear el problema.</p>
            <p>Al enviar el correo o llamarnos explicando lo sucedido, por favor detalle todos los pasos hechos</p>
        </b-notification>
        <div id="section_1">
            <h2 class="title is-2">Seleccione el tipo de carga</h2>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-primary" @click="swapTo2(section2A)">Por producto</button>
                </div>
                <div class="control">
                    <button class="button is-primary" @click="swapTo2(section2B)">Masiva</button>
                </div>
            </div>
        </div>
        <div id="section_2a" style="height: 0; visibility: hidden; opacity: 0%;">
            <button class="button" @click="backTo1">
                <span class="icon is-small">
                    <i class="fas fa-arrow-left"></i>
                </span>
                <span>
                    Atrás
                </span>
            </button>
            <h2 class="title is-2">Seleccione el tipo de producto y el archivo a cargar</h2>
            <form autocomplete="off" @submit.prevent="submitForm">
                <div class="field is-grouped">
                    <div class="control">
                        <b-field label="Tipo de producto" label-position="on-border">
                            <b-select v-model="selectedSubtype" validation-message="Este campo es obligatorio" required placeholder="Ej: Tarjetas" icon="boxes" name="producto">
                                <option
                                    v-for="subtype in subtypes"
                                    :value="subtype.id_tipo_producto"
                                    :key="subtype.id_tipo_producto">
                                    {{ subtype.descripcion }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="control">
                        <file-uploader :required="true" buttontype="is-primary" accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></file-uploader>
                    </div>
                    <div class="control">
                        <button :class="['button', 'is-primary', { 'is-loading' : isLoading }]" type="submit">Cargar</button>
                    </div>
                </div>
            </form>
        </div>
        <div id="section_2b" style="height: 0; visibility: hidden; opacity: 0%;">
            <button class="button" @click="backTo1">
                <span class="icon is-small">
                    <i class="fas fa-arrow-left"></i>
                </span>
                <span>
                    Atrás
                </span>
            </button>
            <h2 class="title is-2">Cargue el archivo</h2>
            <form autocomplete="off" @submit.prevent="submitForm">
                <div class="field is-grouped">
                    <div class="control">
                        <file-uploader :required="true" buttontype="is-primary" accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></file-uploader>
                    </div>
                    <div class="control">
                        <button :class="['button', 'is-primary', { 'is-loading' : isLoading }]" type="submit">Cargar</button>
                    </div>
                </div>
            </form>
        </div>
        <div id="finish" style="height: 0; visibility: hidden; opacity: 0%;">
            <h2 class="title is-2">Su carga está en cola,</h2>
            <div>y procesandose para integrarse al sistema</div>
            <button class="button is-primary" @click="restart">Realizar otra carga</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MassiveProductUpload',
    props: {
        api: {
            default: '',
            type: String
        },
        productTypes: {
            type: Array
        }
    },
    data() {
        return {
            subtypes: [],
            errors: [],
            section1: HTMLDivElement,
            section2A: HTMLDivElement,
            section2B: HTMLDivElement,
            finish: HTMLDivElement,
            currentSection2: HTMLDivElement,
            isLoading: false,
            fileTooLargeError: false,
            otherError: false,
            selectedSubtype: null,
        }
    },
    created() {
        _.forEach(this.productTypes, productType => {
            this.subtypes = _.concat(this.subtypes, productType.subtypes)
        })
    },
    mounted() {
        this.section1 = document.querySelector("#section_1")
        this.section2A = document.querySelector("#section_2a")
        this.section2B = document.querySelector("#section_2b")
        this.finish = document.querySelector("#finish")
    },
    computed: {
        hasErrors() {
            return this.errors.length > 0
        }
    },
    methods: {
        submitForm(form) {
            this.isLoading = true
            let producto = form.target.elements.producto
            let archivo = form.target.elements.archivo

            let submitForm = new FormData()
            submitForm.append('archivo', archivo.files[0])

            if (producto) {
                submitForm.append('producto', producto.value)
                submitForm.append('withProduct', true)
            }

            axios
                .post(this.api, submitForm, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.isLoading = false
                    this.selectedSubtype = null
                    this.goToFinish()
                })
                .catch(error => {
                    this.isLoading = false
                    if (error.response.status == 400)
                        this.errors = _.values(error.response.data.customMessages)
                    else if (error.response.status == 413)
                        this.fileTooLargeError = true
                    else
                        this.otherError = true
                })
        },
        swapTo2(section2) {
            this.currentSection2 = section2
            this.section1.style.opacity = 0
            this.section1.style.height = 0
            this.currentSection2.style.visibility = "hidden"
            setTimeout(() => {
                this.currentSection2.style.opacity = 1
                this.currentSection2.style.height = "100%"
                this.currentSection2.style.visibility = "visible"
            }, 300)
        },
        backTo1() {
            this.currentSection2.style.opacity = 0
            this.currentSection2.style.height = 0
            this.currentSection2.style.visibility = "hidden"
            setTimeout(() => {
                this.section1.style.opacity = 1
                this.section1.style.height = "100%"
                this.section1.style.visibility = "visible"
            }, 300)
        },
        goToFinish() {
            this.currentSection2.style.opacity = 0
            this.currentSection2.style.height = 0
            this.currentSection2.style.visibility = "hidden"
            setTimeout(() => {
                this.finish.style.opacity = 1
                this.finish.style.height = "100%"
                this.finish.style.visibility = "visible"
            }, 300)
        },
        restart() {
            this.currentSection2 = null
            this.finish.style.opacity = 0
            this.finish.style.height = 0
            this.finish.style.visibility = "hidden"
            this.section2A.style.opacity = 0
            this.section2A.style.height = 0
            this.section2A.style.visibility = "hidden"
            this.section2B.style.opacity = 0
            this.section2B.style.height = 0
            this.section2B.style.visibility = "hidden"
            setTimeout(() => {
                this.section1.style.opacity = 1
                this.section1.style.height = "100%"
                this.section1.style.visibility = "visible"
            }, 300)
        }
    }
}
</script>

<style lang="scss" scoped>
    #section_1 {
        transition: all .3s ease-in-out;
    }

    #section_2b {
        transition: all .3s ease-in-out;
    }

    #section_2a {
        transition: all .3s ease-in-out;
    }

    #finish {
        transition: all .3s ease-in-out;
    }
</style>
