<template>
    <div>
        <b-field grouped>

            <b-field label="Código de barras" for="codigo_barra" label-position="on-border" expanded>
                <b-input autocomplete="off" name="codigo_barra" expanded v-model="barcode" icon="barcode" />
            </b-field>


        </b-field>


        <b-field grouped class="j-SB">

            <button expanded class="button is-primary has-margin-left-10" type="submit">Buscar</button>
        </b-field>
    </div>
</template>

<script>
    export default {
        name:'ClientConsultDeliveredProduct',
        props: ['types', 'status', 'values', 'hasLocations', 'locations', 'callings'],
        data() {
            const today = new Date()
            return {
                all: "all",
                clientName: null,
                barcode: null,
                clientID: null,
                productType: null,
                productStatus: null,
                startDate: null,
                endDate: null,
                locationSelected: null,
                isPending: false,
                isFinish: false,
                isBadFormalization: false,
                isFixed: false,
                isUnregistered: false ,
                dateFormat: (date) => date.toLocaleDateString('es-CR'),
                maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
                zona: null,
                biometrics: false,
                istmoFix: false,
                entregaAgencia: false,
            }
        },
        mounted() {
            if (this.values != null)
            {
                if (Object.keys(this.values).length > 0)
                {
                    this.clientName = this.values.nombre
                    this.barcode = this.values.codigo_barra
                    this.clientID = this.values.cedula
                    this.productType = this.values.tipo_producto
                    this.productStatus = this.values.estado
                    this.isPending = this.values.pendiente_externo != null
                    this.isFinish = this.values.finalizado_externo != null
                    this.isBadFormalization = this.values.malas_formalizaciones != null
                    this.isFixed = this.values.malas_formalizaciones_corregidas !=null
                    this.locationSelected = this.values.sede
                    this.isUnregistered = this.values.sin_registro != null
                    this.biometrics = this.values.biometricas != null
                    this.zona = this.values.zona
                    this.istmoFix = this.values.correcion_istmo != null
                    this.entregaAgencia  = this.values.agencia != null

                    if (this.values.fecha_inicio != undefined)
                    {
                        let startDate = this.values.fecha_inicio.split('/')
                        this.startDate = new Date(parseInt(startDate[2]), parseInt(startDate[1])-1, parseInt(startDate[0]))
                    }

                    if (this.values.fecha_final != undefined)
                    {
                        let endDate = this.values.fecha_final.split('/')
                        this.endDate = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]))
                    }
                }
            }
        },
        methods: {
            isUnregisteredCheck() {
                if (this.isUnregistered) {
                    this.isFinish = false
                    this.isBadFormalization = false
                    this.isPending = false
                    this.entregaAgencia = false
                    this.isFixed = false
                }
            },
            isRegisteredCheck() {
                if (this.isFinish || this.isBadFormalization || this.isPending || this.isFixed)
                {
                    this.isUnregistered = false
                    this.entregaAgencia = false
                }
            },
            isAgencyCheck() {
                if (this.entregaAgencia) {
                    this.isFinish = false
                    this.isBadFormalization = false
                    this.isPending = false
                    this.isUnregistered = false
                    this.isFixed = false
                }

            }
        }
    }
</script>
