<template>
  <div>
      <div class="columns">
        <div class="column is-8">
            <section>
                <div class="message-header">
                    Detalles del formalizador
                </div>
                <div class="message-body">
                    <div class="media align-center">
                        <div class="media-left">
                            <figure class="image is-128x128 img-thumbnail rounded-circle img-person"><img :src=" imgPath + messenger.person.person_photo" :alt="'Foto de ' + messenger.nombre_empleado_reportes" class="is-rounded"></figure>
                        </div>
                        <div class="media-content">
                            <div class="title is-4">
                                {{ messenger.nombre_empleado_reportes }}
                            </div>
                            <div>
                                <div class="subtitle is-6">ID: {{ messenger.pk_empleado }} <strong></strong></div>
                                <div class="subtitle is-6">Zona: <strong>{{ messenger.zona_mensajero }}</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="column is-4">
            <section>
                <div class="message-header">
                    Búsqueda
                </div>
                <div class="message-body">
                    <form autocomplete="off" :action="api" method="post" @submit.prevent.stop="onSubmit">
                        <b-field label="Fecha:" label-position="on-border">
                            <b-datepicker
                                placeholder="Seleccione una fecha"
                                icon="calendar-day"
                                name="dia_trabajo"
                                :date-formatter="(date) => date.toLocaleDateString('es-CR')"
                                :month-names='["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]'
                                :day-names='["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"]'
                                v-model="diaTrabajo">
                            </b-datepicker>
                        </b-field>
                        <b-field label="Ver reporte de:" label-position="on-border">
                            <b-select v-model="tipoReporte" name="tipo_reporte" placeholder="Seleccione una opción" icon="running">
                                <option value="">No aplica</option>
                                <option value="reporte">Reporte de posición</option>
                                <option value="esfuerzo">Trabajo sobre producto</option>
                            </b-select>
                        </b-field>
                        <span class="field">
                            <span class="control">
                                <button class="button is-primary" type="submit">Filtrar</button>
                                <a class="button" href="#" @click.prevent.stop="reset">Actualizar</a>
                            </span>
                        </span>
                    </form>
                </div>
            </section>
        </div>
    </div>
    <div class="columns">
        <div class="column is-5">
            <section>
                <div class="message-header">
                    Lista de acciones
                </div>
                <div class="message-body" style="height: 600px; overflow-y:auto;">
                    <div class="box" v-for="(detail, i) in details" :key="i">
                        <article class="media">
                            <div class="media-content">
                                <div class="content">
                                    <p><strong>Acción: </strong>{{ detail.accion }} / {{ detail.descripcion }}</p>
                                    <p v-show="detail.id_producto > 0"><strong>Producto: </strong> {{ detail.id_producto }}</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
        <div class="column is-7">
            <div ref="gmapContainer" class="notification" style="width: 100%; height: 100%; padding: 0;">
                <GmapMap
                    :center="{ lat: center.lat, lng: center.lng }"
                    :zoom="zoom"
                    map-type-id="roadmap"
                    style="width: 100%; height: 100%;"
                    :options="{
                        streetViewControl: false,
                        styles: styles,
                        mapTypeControl: false,
                        rotateControl: false,
                    }"
                    ref="detailMap">
                    <GmapInfoWindow :options="infoOptions" :position="infoWindow.position" :opened="infoWindow.isOpen" @closeclick="infoWindow.isOpen = false" />
                    <GmapMarker
                        v-for="(detail, i) in mapDetails"
                        :key="i"
                        :position="detail.coordinates"
                        :clickable="true"
                        :draggable="false"
                        :icon="detail.icon"
                        @click="toggleInfoMessage(detail, i)"
                    />
                    <GmapPolyline
                        v-if="pathLine.length > 0"
                        :path="pathLine"
                        :options="{
                            strokeOpacity: 0,
                            icons: [{
                                icon: pathIcon,
                                offset: '0',
                                repeat: '30px'
                            }]
                        }"
                    />
                </GmapMap>
                <b-loading :is-full-page="false" :active="isTrackingLoading"></b-loading>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import * as GoogleMaps from 'vue2-google-maps'
import {gmapApi} from 'vue2-google-maps'
import vue from 'vue'

vue.use(GoogleMaps, {
    load: {
        key: 'AIzaSyBQH4ppr3zGrh5AE_Ib3Sth6rqadUUW4xs',
        libraries: 'places',
    },
})

export default {
    name: 'MessengerDetails',
        props: {
            messenger: {},
            api: '',
        },
    data() {
        return {
            details: Array,
            tipoReporte: '',
            diaTrabajo: null,
            imgPath: process.env.MIX_IMG_PERSONAL_INTERNO,
            iconsLocation: '/img/maps/icons/',
            mapDetails: [],
            pathLine: [],
            pathIcon: {
                path: 'M7,15L12,10L17,15H7Z',
                strokeOpacity: 1,
                scale: 1,
                fillOpacity: 0.5,
                // anchor:
            },
            isTrackingLoading: false,
            center: { lat: 10.1040512183681, lng: -84.193679234013 },
            zoom: 9,
            infoWindow: { position: null, isOpen: false, currentMessengerId: null },
            infoOptions: {
                content: '',
                // offset opcional para mejor posicion
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            icons:{
                'Posición actual': 'Moto.png',
                'Acceso o Salida': 'accesoSalida.png',
                'Esfuerzo registrado': 'esfuerzoRegistrado.png',
                'GPS apagado': 'gpsOff.png',
                'Incidente': 'incidente.png',
                'Reporte Posición': 'esfuerzoRegistrado.png',
                'Producto Formalizado': 'pFormalizado.png',
                'Producto devuelto': 'pDevuelto.png',
                'Producto Reprogramado': 'repro.png',
                'Producto Reprogramado C2A': 'reproC2A.png',
                'Producto Reprogramado C2B': 'reproC2B.png',
                'Formalizador': 'Moto.png',
                'Producto para reintentar': 'pReintentar.png',
                'Rechazado': 'rechazdo.png',
                'Abandona la formalización': 'abandonaFormalizacion.png',
            },
            styles: [{
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#f1cc4e"
                        }]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#523735"
                        }]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#f5f1e6"
                        }]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [{
                            "color": "#c9b2a6"
                        }]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#ae9e90"
                        }]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#ead684"
                        }]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#eed77b"
                        }]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#d8bf69"
                        }]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#93817c"
                        }]
                    },
                    {
                        "featureType": "poi.business",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#447530"
                        }]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#f5f1e6"
                        }]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#e98d58"
                        }]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry.stroke",
                        "stylers": [{
                            "color": "#db8555"
                        }]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#806b63"
                        }]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#dfd2ae"
                        }]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#8f7d77"
                        }]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#ebe3cd"
                        }]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#dfd2ae"
                        }]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#b9d3c2"
                        }]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#92998d"
                        }]
                    }],
        }
    },
    computed: {
        detailsReversed() {
            let localDetails = this.details
            return _.reverse(localDetails)
        },
    },
    mounted() {
        this.getLocations()
    },
    methods: {
        onSubmit() {
            this.getLocations()
        },
        reset() {
            this.tipoReporte = ''
            this.diaTrabajo = null
            this.getLocations()
        },
        getLocations() {
            let request = { idMensajero: this.messenger.pk_empleado }

            if (this.tipoReporte)
                request.tipoReporte = this.tipoReporte

            if (this.diaTrabajo)
                request.diaTrabajo = this.diaTrabajo

            axios
                .post(this.api, request)
                .then(response => {
                    this.details = response.data
                    this.prepareLocations()
                })
                .catch(error => {
                    console.log(error)
                    // this.isDataLoading = false
                })
        },
        prepareLocations() {
            let details = _.cloneDeep(this.details)

            this.mapDetails = []
            this.pathLine = []

            _.forEach(details, (detail, i) => {
                let time = new Date(detail.fecha_hora)
                let point = { lat: Number.parseFloat(detail.latitud), lng: Number.parseFloat(detail.longitud) }
                let mapDetail = {
                    icon: this.iconsLocation + (i == 0 ? 'start.png' : i == details.length -1 ? 'finish.png' : this.icons[detail.accion]),
                    coordinates: point,
                    info: `<div class="card">
                            <div class="card-content">
                                <div class="content">
                                <div class="title is-6 has-text-primary">Reporte #${ i + 1 }</div>
                                <div class="title is-6"><strong>Acción: </strong>${ detail.accion }</div>
                                <div class="title is-6"><strong>Descripción: </strong>${ detail.descripcion }</div>
                                <div class="title is-6"><strong>Hora: ${ time.toLocaleTimeString() }</strong></div>
                                </div>
                            </div>
                           </div>`,
                }

                this.mapDetails.push(mapDetail)
                this.pathLine.push(point)
            })
        },
        toggleInfoMessage(messenger, index) {
            this.infoWindow.position = messenger.coordinates
            this.infoOptions.content = messenger.info

            if (this.infoWindow.currentMessengerId === index)
                this.infoWindow.isOpen = !this.infoWindow.isOpen
            else
            {
                this.infoWindow.isOpen = true
                this.infoWindow.currentMessengerId = index
            }
        }
    }
}
</script>
