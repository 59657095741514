<template>
    <div>
        <b-field expanded label="Plantillas" label-position="on-border">
            <b-select placeholder="Seleccione plantilla" @change.native="onTemplateSelected" v-model="selectedTemplate" name="id_plantilla" expanded>
                <option v-for="template in templates" :value="template.id" :key="template.id">
                    {{ template.nombre }}
                </option>
            </b-select>
        </b-field>
        <b-field expanded label="Mensaje" label-position="on-border">
            <b-input autocomplete="off" type="textarea" placeholder="Mensaje a enviar" name="mensaje" :value="messageToUse" expanded required></b-input>
        </b-field>
        <b-field grouped>
            <b-field expanded label="Fecha de envío" label-position="on-border">
                <b-datepicker
                    :date-formatter="dateFormat"
                    name="fecha_envio"
                    icon="calendar-day"
                    required
                    expanded
                    :month-names='["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]'></b-datepicker>
            </b-field>
            <b-field expanded label="Hora de envío" label-position="on-border">
                <b-clockpicker
                    icon="clock"
                    name="hora_envio"
                    expanded
                    required
                    :time-formatter="timeFormat"
                    hours-label="Horas"
                    minutes-label="Min"></b-clockpicker>
            </b-field>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'EmmSendMnt',
        props: {
            templates: Array,
        },
        data () {
            return {
                selectedTemplate: 0,
                dateFormat: (date) => date.toLocaleDateString('es-CR'),
                messageToUse: '',
                timeFormat: (date) => date.toLocaleTimeString('es-CR')
            }
        },
        mounted() {
        },
        methods: {
            onTemplateSelected()
            {
                this.messageToUse = (_.find(this.templates, { id: this.selectedTemplate })).mensaje
            }
        }
    }
</script>
