<template>
    <div>
        <b-field grouped>
            <b-field>
                <img :src="data.img" alt="Logo cliente">
            </b-field>
            <b-field>
                <p style="display: block;">Cliente: {{client.name}}</p>
            </b-field>
        </b-field>
        <b-notification v-if="data.status" type="is-success">Se registró el producto exitosamente</b-notification>
        <b-notification v-if="barcodeInUse" type="is-danger">El producto registrado tiene un <strong>código de barra repetido</strong></b-notification>
        <b-field grouped>
            <b-field label="Tipo de producto" label-position="on-border" expanded>
                <b-select v-model="pro_id" name="id_tipo_subproducto" required icon="boxes" placeholder="Ej: Préstamos" expanded validation-message="Por favor, escoja un producto">
                    <option disabled>Seleccione un producto</option>
                    <option v-for="product in products" :key="product.id_tipo_producto" :value="product.id_tipo_producto">{{product.descripcion}}</option>
                </b-select>
            </b-field>
            <b-field v-if="data.permitido" label="Código de barra" label-position="on-border" expanded>
                <b-input autocomplete="off" v-model="barcode" name="codigo_barra" icon="barcode" required expanded validation-message="Este campo es obligatorio"></b-input>
            </b-field>
        </b-field>
        <hr>
        <b-field grouped group-multiline>
                <b-field label="Número de cédula" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="numero_cedula" icon="id-card" required validation-message="Este campo es obligatorio"></b-input>
                </b-field>
                <b-field label="Nombre" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="nombre_cliente" icon="user" required validation-message="Este campo es obligatorio"></b-input>
                </b-field>
                <b-field label="Primer apellido" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="primer_apellido_cliente" required validation-message="Este campo es obligatorio"></b-input>
                </b-field>
                <b-field label="Segundo apellido" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="segundo_apellido_cliente" required validation-message="Este campo es obligatorio"></b-input>
                </b-field>
            </b-field>
            <hr>
            <b-field grouped>
                <b-field label="Teléfono de casa" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="telefono_casa" type="tel" pattern="[0-9]{4}-[0-9]{4}" icon="home"  validation-message="Por favor, ingrese un número telefónico en el siguiente formato XXXX-XXXX"></b-input>
                </b-field>
                <b-field label="Teléfono celular" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="telefono_celular" type="tel" pattern="[0-9]{4}-[0-9]{4}" icon="mobile" validation-message="Por favor, ingrese un número telefónico en el siguiente formato XXXX-XXXX"></b-input>
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field label="Teléfono de oficina" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="telefono_oficina" type="tel" pattern="[0-9]{4}-[0-9]{4}" icon="phone" validation-message="Por favor, ingrese un número telefónico en el siguiente formato XXXX-XXXX"></b-input>
                </b-field>
                <b-field label="Extensión" label-position="on-border" expanded>
                    <b-input autocomplete="off" name="extension" icon="tty"></b-input>
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field label="Email" label-position="on-border" expanded>
                    <b-input autocomplete="off" type="email" name="correo_cliente" icon="envelope"></b-input>
                </b-field>
            </b-field>
            <b-field label="Notas" label-position="on-border">
                <b-input autocomplete="off" type="textarea" name="notas"></b-input>
            </b-field>
            <hr>
             <b-field label="Dirección de entrega:">
                <select-locations :locations="location" :alternative-name="''" :alternative-code="''"></select-locations>
            </b-field>
            <div class="has-margin-top-10"></div>
            <b-field label="Dirección de entrega alternativa:">
                <select-locations :locations="location" :alternative-name="'alternativo'" :alternative-code="'alt'"></select-locations>
            </b-field>
            <div class="has-margin-top-10"></div>

            <div v-if="data.permitido">
                <hr>
                <b-field>
                    <strong>Carga de documentos:</strong>
                </b-field>
                <div  v-for="doc in documents" :key="doc.id_tipo_documento">
                    <div class="columns">
                        <div class="column is-two-fifths">
                            <b-field expanded>
                                <span class="file-label">
                                    {{doc.descripcion_documento_cliente}}
                                </span>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field expanded>
                                <file-uploader-credits-products-documents :required="false"  customColor="#e65b09 !important" accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" :data="{'index':doc.id_tipo_documento}"></file-uploader-credits-products-documents>
                            </b-field>
                        </div>
                        <div class="column">
                            <input type="hidden" :name="'documento'+doc.id_tipo_documento" :value="doc.id_tipo_documento">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <input type="hidden" name="tipo_producto" :value="producto">
            <input type="hidden" name="id_proceso" :value="proceso">
            <button @click="onSubmit" class="button is-primary has-top-margin-20" type="submit">Agregar producto</button>
    </div>
</template>
<script>
export default {
    props:['data', 'client', 'products', 'location', 'proceso', 'producto'],

    data() {
        return {
            pro_id : null,

            barcode : null,

            documents : [],

            barcodeInUse : false,

            incall : false,

            submit : true,

            validarDocumentos : this.data.permitido
        }
    },

    mounted(){

    },

    methods: {
        onSubmit(event){
            var ele = document.getElementById('form')
            var chk_status = ele.checkValidity();
            ele.reportValidity();
            if(this.validarDocumentos){
                event.preventDefault()
                if(this.submit == true && chk_status) {
                    this.$buefy.dialog.confirm({
                        title: 'Atención',
                        message: '¿Está seguro de continuar y enviar la documentación adjuntada?<br><b> Esta acción no es reversible.</b>',
                        confirmText: 'Continuar',
                        type: 'is-warning',
                        hasIcon: true,
                        onConfirm: () => document.getElementById('form').submit()
                    })
                }
            }
        },

        async call(){
            if(this.incall) return
            this.incall = true
            await this.sleep()
            try {
                if(this.barcode == null || this.barcode == ''){
                    this.incall = false
                    this.barcodeInUse = false
                    this.submit = true
                    return
                }
                let b = await window.axios.get(`/app/asm/asm_carga_productos_prestamos_check_barcode/${this.barcode}`)
                this.barcodeInUse = b.data.stateMessage
                this.submit = !b.data.stateMessage
                this.incall = false
            } catch (error) {
                console.log(error)
            }
        },

        sleep() {
            return new Promise(resolve => setTimeout(resolve, 1000));
        }

    },

    watch:{
        pro_id(newValue, oldValue){
            if(newValue){
                    window.axios.get(`/app/asm/asm_carga_productos_prestamos/${newValue}`)
                    .then((res)=> {
                        this.documents = res.data
                        console.log(this.documents)
                    })

                    .catch((error)=> {
                        console.log(error)
                    })

            }
        },

        barcode(newValue, oldValue){
             if(newValue){
                this.call()
            }
        }
    }

}
</script>
