<template>
    <section>
        <b-dropdown trap-focus position="is-bottom-left">
            <button class="button is-link is-fullwidth" slot="trigger" slot-scope="{ active }">
                <span> {{ petition ? 'Ver' : 'Solicitar' }} petición</span>
                <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </button>
            <b-dropdown-item
                :focusable="false"
                custom
                paddingless>
                <div class="modal-card">
                    <section class="modal-card-body" v-if="!petition">
                        <template v-if="errors">
                            <validation-alert :fields="errorList"></validation-alert>
                        </template>
                        <form autocomplete="off" action="#" @submit.prevent="sendRequest">
                            <b-field label="Descripción" label-position="on-border">
                                <b-input autocomplete="off" type="textarea" required validation-message="Este campos es obligatorio" v-model="description"></b-input>
                            </b-field>
                            <b-field>
                                <button type="submit" class="button is-primary">Enviar petición</button>
                            </b-field>
                        </form>
                    </section>
                    <section class="modal-card-body" v-else>
                        <div><strong class="is-size-6">Fecha de creación: </strong>{{ new Date(petition.fh_realizado_por).toLocaleDateString('es-CR') }}</div>
                        <div><strong class="is-size-6">Descripción: </strong>{{ petition.descripcion }}</div>
                    </section>
                </div>
            </b-dropdown-item>
        </b-dropdown>
    </section>
</template>

<script>
    export default {
        name: 'MakePetition',
        props: {
            apiSubmit: String,
            apiGet: String,
            idProducto: Number,
        },
        data() {
            return {
                active: false,
                description: '',
                petition: null,
                success: false,
                errors: false,
                errorList: [],
            }
        },
        created() {
            axios
                .post(this.apiGet)
                .then(response => {
                    if (!_.isEmpty(response.data))
                        this.petition = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        mounted() {
        },
        methods: {
            sendRequest()
            {
                this.errors = false
                this.errorList = []

                axios
                    .post(this.apiSubmit, { idProducto: this.idProducto, descripcion: this.description })
                    .then(response => {
                        console.log(response)
                        if (!response.data.withErrors)
                        {
                            this.petition = response.data.petition
                        }
                        else
                        {
                            this.errors = true
                            this.errorList = response.data.errors['descripcion']
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
</script>
