<template>
    <div>
        <b-field grouped>
            <b-field label="Nombre" for="nombre" label-position="on-border" expanded>
                <b-input autocomplete="off" name="nombre" expanded v-model="clientName" icon="user" />
            </b-field>
            <b-field label="Código de barras" for="codigo_barra" label-position="on-border" expanded>
                <b-input autocomplete="off" name="codigo_barra" expanded v-model="barcode" icon="barcode" />
            </b-field>
            <b-field label="Cédula" for="cedula" label-position="on-border" expanded>
                <b-input autocomplete="off" name="cedula" expanded v-model="clientID" icon="id-card" />
            </b-field>
            <b-field v-show="hasLocations" label="Sedes:" for="sede" label-position="on-border" expanded>
                <b-select name="sede" placeholder="Seleccione una sede" icon="home" v-model="locationSelected">
                    <option value="all">No aplica</option>
                    <option v-for="(location, index) in locations" :key="index" :value="location.id_sede">
                        {{ location.nombre_sede }}
                    </option>
                </b-select>
            </b-field>
        </b-field>
        <b-field grouped>
            <b-field label="Tipo de producto" for="tipo_producto" label-position="on-border">
                <b-select name="tipo_producto" placeholder="Seleccione un producto" icon="pallet" v-model="productType">
                    <!-- <option :value="all">No aplica</option> -->
                    <template v-for="type in types">
                        <option v-for="subtype in type.subtypes" :value="subtype.id_tipo_producto" :key="subtype.id_tipo_producto">
                            {{ subtype.descripcion }}
                        </option>
                    </template>
                </b-select>
            </b-field>
            <b-field label="Estado" for="estado" label-position="on-border">
                <b-select name="estado" placeholder="Seleccione un estado" icon="tasks" v-model="productStatus">
                    <option v-for="state in status" :value="state.id_track_proceso" :key="state.id_track_proceso">
                        {{ state.descripcion_track_proceso }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Zona:" label-position="on-border">
                <b-select name="zona" placeholder="Seleccione una zona" icon="globe" v-model="zona">
                    <!-- <option :value="null">No aplica</option> -->
                    <option :value="'METRO'">Metro</option>
                    <option :value="'RURAL'">Rural</option>
                </b-select>
            </b-field>
            <b-field label="Desde:" for="fecha_inicio" label-position="on-border" expanded>
                <b-datepicker
                    name="fecha_inicio"
                    icon="calendar-alt"
                    v-model="startDate"
                    :date-formatter="dateFormat"
                    :month-names="monthNames"
                    :day-names="dayNames"
                    :max-date="maxDate" />
            </b-field>
            <b-field label="Hasta:" for="fecha_final" label-position="on-border" expanded>
                <b-datepicker
                    name="fecha_final"
                    icon="calendar-day"
                    v-model="endDate"
                    :date-formatter="dateFormat"
                    :month-names="monthNames"
                    :day-names="dayNames"
                    :max-date="maxDate" />
            </b-field>
        </b-field>
        <b-field grouped class="j-SB">
            <template v-if="!callings">
                <b-checkbox expanded @input="isAgencyCheck" name="agencia" v-model="entregaAgencia" type="is-dark">Entregado en agencia</b-checkbox>
                <b-checkbox expanded @input="isRegisteredCheck" name="pendiente_externo" v-model="isPending" type="is-dark" native-value="pendiente_trabajo_externo">Mostrar solo Pendientes de Finalizar (externo)</b-checkbox>
                <b-checkbox expanded @input="isRegisteredCheck" name="finalizado_externo" v-model="isFinish" type="is-dark" native-value="trabajos_finalizados_externo">Mostrar solo Trabajados/Finalizados (externo)</b-checkbox>
                <b-checkbox expanded @input="isRegisteredCheck" name="malas_formalizaciones" v-model="isBadFormalization" type="is-dark" native-value="malas_formalizaciones">Mostrar solo Malas Formalizaciones</b-checkbox>
                <b-checkbox expanded @input="isRegisteredCheck" name="malas_formalizaciones_corregidas" v-model="isFixed" type="is-dark" native-value="malas_formalizaciones_corregidas">Mostrar solo Corregidas</b-checkbox>
            </template>
            <b-checkbox expanded @input="isUnregisteredCheck" name="sin_registro" v-model="isUnregistered" type="is-dark" native-value="sin_registro">Sin trabajar y sin asignar</b-checkbox>
            <b-checkbox expanded name="biometricas" v-model="biometrics">Verificado biometricamente</b-checkbox>
            <b-checkbox expanded name="correcion_istmo" v-model="istmoFix">Corregidas por Istmo</b-checkbox>
            <button expanded class="button is-primary has-margin-left-10" type="submit">Buscar</button>
        </b-field>
    </div>
</template>

<script>
    export default {
        name:'ClientConsultDeliveredProduct',
        props: ['types', 'status', 'values', 'hasLocations', 'locations', 'callings'],
        data() {
            const today = new Date()
            return {
                all: "all",
                clientName: null,
                barcode: null,
                clientID: null,
                productType: null,
                productStatus: null,
                startDate: null,
                endDate: null,
                locationSelected: null,
                isPending: false,
                isFinish: false,
                isBadFormalization: false,
                isFixed: false,
                isUnregistered: false ,
                dateFormat: (date) => date.toLocaleDateString('es-CR'),
                maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
                zona: null,
                biometrics: false,
                istmoFix: false,
                entregaAgencia: false,
            }
        },
        mounted() {
            if (this.values != null)
            {
                if (Object.keys(this.values).length > 0)
                {
                    this.clientName = this.values.nombre
                    this.barcode = this.values.codigo_barra
                    this.clientID = this.values.cedula
                    this.productType = this.values.tipo_producto
                    this.productStatus = this.values.estado
                    this.isPending = this.values.pendiente_externo != null
                    this.isFinish = this.values.finalizado_externo != null
                    this.isBadFormalization = this.values.malas_formalizaciones != null
                    this.isFixed = this.values.malas_formalizaciones_corregidas !=null
                    this.locationSelected = this.values.sede
                    this.isUnregistered = this.values.sin_registro != null
                    this.biometrics = this.values.biometricas != null
                    this.zona = this.values.zona
                    this.istmoFix = this.values.correcion_istmo != null
                    this.entregaAgencia  = this.values.agencia != null

                    if (this.values.fecha_inicio != undefined)
                    {
                        let startDate = this.values.fecha_inicio.split('/')
                        this.startDate = new Date(parseInt(startDate[2]), parseInt(startDate[1])-1, parseInt(startDate[0]))
                    }

                    if (this.values.fecha_final != undefined)
                    {
                        let endDate = this.values.fecha_final.split('/')
                        this.endDate = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]))
                    }
                }
            }
        },
        methods: {
            isUnregisteredCheck() {
                if (this.isUnregistered) {
                    this.isFinish = false
                    this.isBadFormalization = false
                    this.isPending = false
                    this.entregaAgencia = false
                    this.isFixed = false
                }
            },
            isRegisteredCheck() {
                if (this.isFinish || this.isBadFormalization || this.isPending || this.isFixed)
                {
                    this.isUnregistered = false
                    this.entregaAgencia = false
                }
            },
            isAgencyCheck() {
                if (this.entregaAgencia) {
                    this.isFinish = false
                    this.isBadFormalization = false
                    this.isPending = false
                    this.isUnregistered = false
                    this.isFixed = false
                }
            }
        }
    }
</script>
