<template>
    <div>
        <b-button expanded icon-left="plus" type="is-success" class="has-margin-bottom-10" @click="onCreateClick">Agregar</b-button>
        <div class="table-container">
            <table class="table is-striped is-narrow">
                <thead>
                    <tr>
                        <th v-for="(attribute, i) in attributes" :key="i">{{ attribute.displayName }}</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(element, i) in elements" :key="i">
                        <td v-for="(attribute, j) in attributes" :key="j">
                            {{ element[attribute.attributeName] }}
                        </td>
                        <td>
                            <b-button icon-left="edit" type="is-primary" @click="onEditClick(i)">Editar</b-button>
                            <b-button icon-left="trash" type="is-danger" @click="onDeletedClick(element[idField])">Eliminar</b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    const ModalEditForm = {
        name: "ModalEditForm",
        props: {
            attributes: Array,
            element: Object,
            idField: {
                type: String,
                default: 'id'
            },
            api: String,
        },
        data() {
            return {
                values: {}
            }
        },
        created() {
            _.forEach(this.element, (val, key) => {
                this.values[key] = val
            })
        },
        template:  `<form autocomplete="off" action="">
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">Agregar</p>
                            </header>
                            <section class="modal-card-body">
                                <div v-for="(attribute, i) in attributes" :key="i">
                                    <b-field class="has-margin-15" :label="attribute.displayName" label-position="on-border" expanded>
                                        <b-input autocomplete="off" :name="attribute.attributeName" :type="attribute.type" :value="values[attribute.attributeName]" v-model="values[attribute.attributeName]" expanded :required="attribute.required" :validation-message="'Es necesario que este campo esté lleno'"></b-input>
                                    </b-field>
                                </div>
                            </section>
                            <footer class="modal-card-foot">
                                <button class="button" @click.stop.prevent="$parent.close()">Cancelar</button>
                                <button class="button is-primary" type="submit" @click.stop.prevent="updateElement">Actualizar</button>
                            </footer>
                        </div>
                    </form>`,
        methods: {
            updateElement()
            {
                axios
                    .patch(this.api, this.values)
                    .then(response => {
                        if(response.data.updated)
                        {
                            this.$buefy.dialog.alert(
                                {
                                    message: 'Elemento actualizado',
                                    type: 'is-success',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                        else
                        {
                            this.$buefy.dialog.alert(
                                {
                                    title: 'Imposible actualizar',
                                    message: 'Contacte con soporte para hacer actualización manual, revise que no hayan campos no opcionales faltantes',
                                    type: 'is-danger',
                                    icon: 'times',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                    })
                    .catch(error => {
                        this.$buefy.dialog.alert(
                            {
                                title: 'Algo salio mal',
                                message: 'Favor contacte soporte y narre lo sucedido',
                                type: 'is-danger',
                                icon: 'times',
                                hasIcon: true,
                            })
                    })
            },
            refresh()
            {
                this.$parent.$options.parent.refresh()
                this.$parent.close();
            }
        }
    }

    const ModalSubmitForm = {
        name: 'ModalSubmitForm',
        props: {
            attributes: Array,
            idField: {
                type: String,
                default: 'id'
            },
            api: String,
        },
        data() {
            return {
                values: {}
            }
        },
        created() {
            _.forEach(this.attributes, (attribute) => {
                this.values[attribute.attributeName] = null
            })
        },
        template:  `<form autocomplete="off" action="">
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">Editar</p>
                            </header>
                            <section class="modal-card-body">
                                <div v-for="(attribute, i) in attributes" :key="i">
                                    <b-field class="has-margin-15" :label="attribute.displayName" label-position="on-border" expanded>
                                        <b-input autocomplete="off" :name="attribute.attributeName" :type="attribute.type" v-model="values[attribute.attributeName]" expanded :required="attribute.required" :validation-message="'Es necesario que este campo esté lleno'"></b-input>
                                    </b-field>
                                </div>
                            </section>
                            <footer class="modal-card-foot">
                                <button class="button" @click.stop.prevent="$parent.close()">Cancelar</button>
                                <button class="button is-primary" type="submit" @click.stop.prevent="createElement">Agregar</button>
                            </footer>
                        </div>
                    </form>`,
        methods: {
            createElement()
            {
                axios
                    .post(this.api, this.values)
                    .then(response => {
                        if(response.data.added)
                        {
                            this.$buefy.dialog.alert(
                                {
                                    message: 'Elemento agregado',
                                    type: 'is-success',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                        else
                        {
                            this.$buefy.dialog.alert(
                                {
                                    title: 'Imposible agregar',
                                    message: 'Contacte con soporte para agregar manualmente, revise que no hayan campos no opcionales faltantes',
                                    type: 'is-danger',
                                    icon: 'times',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                    })
                    .catch(error => {
                        this.$buefy.dialog.alert(
                            {
                                title: 'Algo salio mal',
                                message: 'Favor contacte soporte y narre lo sucedido',
                                type: 'is-danger',
                                icon: 'times',
                                hasIcon: true,
                            })
                    })
            },
            refresh()
            {
                this.$parent.$options.parent.refresh()
                this.$parent.close();
            }
        }
    }

    export default {
        name: 'CurdTable',
        props: {
            attributes: Array,
            elements: Array,
            idField: {
                type: String,
                default: 'id',
            },
            api: String,
        },
        mounted() {
        },
        methods: {
            onCreateClick()
            {
                this.$buefy.modal.open({
                    parent:this,
                    component: ModalSubmitForm,
                    hasModalCard: true,
                    props: {
                        attributes: this.attributes,
                        idField: this.idField,
                        api: this.api,
                    },
                    trapFocus: true
                })
            },
            onEditClick(index)
            {
                this.$buefy.modal.open({
                    parent: this,
                    component: ModalEditForm,
                    hasModalCard: true,
                    props: {
                        attributes: this.attributes,
                        element: this.elements[index],
                        idField: this.idField,
                        api: this.api,
                    },
                    trapFocus: true
                })
            },
            onDeletedClick(id)
            {
                this.$buefy.dialog.confirm(
                    {
                        title: 'Borrar elemento',
                        message: 'Esta acción es <strong class="has-text-danger">irreversible</strong>. ¿Seguro que desea continuar?',
                        confirmText: 'Eliminar',
                        type: 'is-danger',
                        hasIcon: true,
                        cancelText: 'Cancelar',
                        onConfirm: () => this.deletedElement(id)
                    }
                )
            },
            deletedElement(id)
            {
                axios
                    .delete(this.api, { data: { id: id } })
                    .then(response => {
                        if (response.data.deleted)
                        {
                            this.$buefy.dialog.alert(
                                {
                                    message: 'Elemento eliminado',
                                    type: 'is-success',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                        else
                        {
                            this.$buefy.dialog.alert(
                                {
                                    title: 'Imposible eliminar',
                                    message: 'Contacte con soporte para hacer eliminación manual',
                                    type: 'is-danger',
                                    icon: 'times',
                                    hasIcon: true,
                                    onConfirm: () => this.refresh()
                                })
                        }
                    })
                    .catch(error => {
                        this.$buefy.dialog.alert(
                            {
                                title: 'Algo salio mal',
                                message: 'Favor contacte soporte y narre lo sucedido',
                                type: 'is-danger',
                                icon: 'times',
                                hasIcon: true,
                            })
                    })
            },
            refresh()
            {
                window.location.reload(true)
            }
        }
    }
</script>
